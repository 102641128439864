<section class="login_page">
  <div class="container-fluid ">
    <div class="row form-group">
      <div class="col-md-4  text-white  d-flex flex-column justify-content-center" id="col1">
        <div><label class=" text-white ml-3" id="labelHi">Hi,</label>
          <p class="text-white  ml-3" id="pWelcome">Welcome to Listingfy Admin</p></div>
      </div>
      <div class="col-md-4  m-auto" id="col2">
        <div class="login_form-wrapper">
          <div class=" card card-view shadow-sm p-5 bg-white">
            <form [formGroup]="formMobile" (submit)="otpSent ? login() : getLoginOtp()" ngNativeValidate>
              <label class="h3 mb-1 login-view-heading">Login to continue</label>
              <p class="small mb-4 text-secondary login-view-sub-heading">Login now to access your admin controls of
                Listingfy. </p>
              <div class="mb-4 form-group">
                <div class="form-floating mb-3 mr-4">
                  <input type="text" formControlName="mobile" maxlength="10" minlength="10" class="form-control" pattern="[0-9]*" id="floatingInput"
                         placeholder="name@example.com" [disabled]="otpSent" required >
                  <label for="floatingInput" class="ml-2 login-labels">Mobile Number</label>
                </div>
                <div *ngIf="otpSent" class="form-floating mb-3 mr-4">
                  <input type="text" formControlName="otp" class="form-control" maxlength="6" minlength="6"
                         pattern="[0-9]*" id="floatingInput2" placeholder="OTP" required>
                  <label for="floatingInput2" class="ml-2 login-labels">OTP</label>
                </div>
              </div>
                <app-loader-button [isLoading]="loading" [buttonText]="otpSent ? 'Continue' : 'Send OTP'" [wrapperClass]="'w-100'"
                                   [customButtonClass]="'btn-dark w-100'"></app-loader-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>












