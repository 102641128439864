import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, filter, finalize, Observable, switchMap, take, throwError,catchError} from "rxjs";
import {LoginService} from "./login.service";
import {Router} from "@angular/router";
import {SessionManager} from "../shared/SessionManager";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService  implements HttpInterceptor {

  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private service: LoginService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.data.isLoading.next(true);
    let authReq = request;
    const url: string = request.url;

    if (url.includes('login') || url.includes('oauth/token')) {
      return next.handle(request);
    }
    const token = SessionManager.getToken();
    if (token) {
      request = this.addToken(request,token);
    }
    // @ts-ignore
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(authReq, next);
      } else if(error instanceof HttpErrorResponse && error.status === 403){
        window.location.reload();
        // alert("Your session expires!!");
        localStorage.clear();
        console.log("=======refresh token is give error====");
        this.isRefreshing = false;
        // this.data.isLoading.next(false);
        this.router.navigate(['/login']);
      }
      finalize(
        () => {
          // this.data.isLoading.next(false);
        })
      return throwError(() => error);
    }));

  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      //reset here so that the following requests wait until the token
      //comes back from the refreshtoken
      this.refreshTokenSubject.next(null);
      this.addBasicAuthToken(req);
      const refreshToken: any = SessionManager.getRefreshToken();
      const data =new FormData();
      data.set("refresh_token",refreshToken);
      data.set("grant_type","refresh_token");
      if (refreshToken)
      return this.service.refreshToken(refreshToken,data).pipe(
          switchMap((value: any, index) => {
              this.isRefreshing = false;
              console.log('value',value);
              let newAccessToken = value.access_token;
              let newRefreshToken = value.refresh_token;
              SessionManager.updateSession(newAccessToken,newRefreshToken);
              console.log("if login token is expired then refresh token will be generated");
              this.refreshTokenSubject.next(newAccessToken);
              return next.handle(this.addToken(req, newAccessToken));
            }
          )
        );
      console.log('Send Refresh Api call')
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addToken(req, token)))
    );
  }

  private addBasicAuthToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'authorization': `Basic YWNtZTphY21lc2VjcmV0`
      }
    });
  }

  private addToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: {
        'authorization': `Bearer ${token}`
      }
    });
  }
}
