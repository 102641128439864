
<header class="main-header" id="header">
  <div class="sb-nav-fixed mr-3">
    <nav class="navbar navbar-light header-bottom">
      <button type="button"  id="sidebarCollapse" class="btn text-sidebar ">
<!--        <i class="fas fa-search"></i>-->
        <i class="fas fa-bars "></i>
<!--        <span> <input class="header-search" type="text"  name="name" placeholder="Search here..."></span>-->
      </button>
<!--      <i class="fa fa-bell"></i>-->
      <div class="navbar-nav header-right">
        <li class="nav-item dropdown header-profile">
          <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">

            <div class="circle">
              <span  class="initials">{{getInitials(user.name)}}</span>
<!--              <img  class="circle bg-white" [src]="">-->
            </div>
            <div class="header-info mx-2">
              <span class="text-black"><strong>{{user.name}}</strong></span>
              <p class="mb-0">{{user.roleName}}</p>
            </div>
          </a>
          <a class="nav-link" role="button">
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" routerLink="userProfile">
                <span><i class="fas fa-user mx-2"></i>Profile</span>
              </a>
              <a class="dropdown-item" routerLink="changePassword">
                <span><i class="fas fa-lock mx-2"></i>Change Password</span>
              </a>
              <a class="dropdown-item" (click)="logout()">
                <span><i class="fas fa-sign-out-alt mx-2"></i>Logout</span>
              </a>
            </div>
          </a>
        </li>
      </div>
    </nav>
  </div>
</header>
