import { Component, OnInit } from '@angular/core';
import {DataService} from "../services/data.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

isLoading=false;

  constructor(private router: Router, public data: DataService) { }

  ngOnInit(): void {
    this.data.isLoading.subscribe(res => {
      this.isLoading = res;
    });
  }

}
