export const Constants = {
  storageKeys: {
    UserId: "userId",
    IsLoggenIn: "isLoggedIn",
    AccessToken: "accessToken",
    RefreshToken: "refreshToken",
    UserRole: "userRole",
    SessionUser: "sessionUser"
  },
}
