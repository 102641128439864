import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor() { }

  dialogConfig(data: any): any {
    return {
      width: "40%",
      height: "100%",
      data,
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 400 }
        },
      },
      position: {
        rowEnd: "60%",
      }
    }
  }
}
