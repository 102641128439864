// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://api.carvia-test.org',
  baseUrl: 'https://api.carvia-test.org/lfy-service',
  onboardBaseUrl:'https://api.carvia-test.org/visor-service',
  authUrlVisor:'https://auth.carvia-test.org/visor',
  basicAuth: 'YWNtZTphY21lc2VjcmV0'
};

// export const environment = {
//   production: true,
//   url: 'https://api.carviatech.net',
//   baseUrl: 'https://api.carviatech.net/lfy-service',
//   onboardBaseUrl:'https://api.carviatech.net/visor-service',
//   basicAuth: 'YWNtZTphY21lc2VjcmV0'
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
