import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {SessionManager} from "../../../shared/SessionManager";

@Component({
  selector: 'app-sidebar',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.css']
})
export class MenuNavigationComponent implements OnInit {
  @ViewChild('channels') channels: ElementRef<HTMLLinkElement> | any;

  user: any;
  dashboardActive = false;
  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user = SessionManager.getUser();
    this.router.events.subscribe((res:any) => {
      if (res instanceof NavigationEnd) {
        if(res.url == '/onBoarding-Users'){
          this.dashboardActive = true;
        }
      }
    });
  }



}
