import {Constants} from "./Constants";
import {User, UserRole} from "./interfaces";

export class SessionManager {
  static isLoggedIn = (): boolean =>  {
    return localStorage.getItem(Constants.storageKeys.IsLoggenIn) === "true";
  }
  static setLoggedIn = (isLoggedIn: boolean) =>  {
    localStorage.setItem(Constants.storageKeys.IsLoggenIn, isLoggedIn?"true":"false");
  }
  static getToken = () => {
    return localStorage.getItem(Constants.storageKeys.AccessToken);
  }
  static getRefreshToken = () => {
    return localStorage.getItem(Constants.storageKeys.RefreshToken);
  }
  static updateSession = (token: string, refreshToken?: string) => {
    if (refreshToken) {
      localStorage.setItem(Constants.storageKeys.RefreshToken, refreshToken);
    }
    this.setLoggedIn(true);
    localStorage.setItem(Constants.storageKeys.AccessToken, token);
  }
  static createSession = (token: string,userId: string, refreshToken?: string, fullName?:string, userName?:string, roleName?: UserRole) => {
    if (refreshToken) {
      localStorage.setItem(Constants.storageKeys.RefreshToken, refreshToken);
    }
    this.setLoggedIn(true);
    localStorage.setItem(Constants.storageKeys.SessionUser, JSON.stringify(
      {
        'userId': userId,
        'name': fullName||'',
        'userName': userName,
        'roleName': roleName||''})
    );
    localStorage.setItem(Constants.storageKeys.UserRole, roleName??'');
    localStorage.setItem(Constants.storageKeys.AccessToken, token);
  }

  static getUser(): User {
    return  JSON.parse(localStorage.getItem(Constants.storageKeys.SessionUser)??"{}");
  }

  static clearSession = () => {
    localStorage.removeItem(Constants.storageKeys.AccessToken);
    localStorage.removeItem(Constants.storageKeys.UserRole);
    localStorage.removeItem(Constants.storageKeys.RefreshToken);
    localStorage.removeItem(Constants.storageKeys.SessionUser);
    localStorage.setItem(Constants.storageKeys.IsLoggenIn, "false");
  }
}
