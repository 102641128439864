import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SessionManager} from "../../../shared/SessionManager";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any;
  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.user = SessionManager.getUser();
  }

  logout() {
    SessionManager.clearSession();
    this.router.navigate(['login']);
  }

  getInitials(string: any): string {
    let names = string.trim().replace(/_/g, '').split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

}
