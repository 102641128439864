
<div class="wrapper col-2">
  <aside  class="left-sidebar" id="side-bar">
    <!--      <div class="side-header active"> <a class="header-brand1" style="display: flex; align-items: center;">-->
    <!--        <i class="fas fa-user-tie mx-2"></i><span>Vendor Portal</span></a> &lt;!&ndash; LOGO &ndash;&gt; -->
    <!--      </div>-->
    <div class="sidebar flex-column  min-vh-100 position-relative overflow-hidden h-100 d-flex sidebar-with-footer">
      <div class="position-relative flex-column flex-wrap align-content-start justify-content-start align-items-start h-100">
        <div class="simplebar-content-wrapper">
          <div class="simplebar-content p-0">
            <div class="user-sidebar text-center">
              <div class="dropdown">
                <div class="user-img">
                  <img src="./assets/Carvia_logo.png">
                </div>
                <div class="user-info">
                  <h5 class="mt-3 font-size-16 text-black">Carvia Tech</h5>
                  <span class="font-size-13 text-black-50">{{user.roleName}}</span>
                </div>
              </div>
            </div>
            <ul class="sidebar-inner navbar-nav d-flex flex-column flex-wrap list-unstyled p-0">
<!--              <li class="nav-item" [routerLinkActive]="[dashboardActive ? 'active': '']">-->
<!--                <a [routerLink]="['/dashboard']" class="side-menu_item nav-link">-->
<!--                  <div class="mx-3">-->
<!--                    <i class="fas fa-home mx-2"></i>-->
<!--                    <span class="nav-text">Dashboard</span>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </li>-->

              <li class="nav-item" [routerLinkActive]="['active']">
                <a  [routerLink]="['./onBoarding-Users']" class="side-menu_item nav-link">
                  <div class="mx-3">
                    <i class="fas fa-user-friends mx-2"></i>
                    <span class="nav-text">Visitors Users</span>
                  </div>
                </a>
              </li>
            </ul>
            <div style="height: 200px"></div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</div>
