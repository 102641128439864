import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "./app-routing-module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MenuNavigationComponent} from './content/admin/menu-navigation/menu-navigation.component';
import {HeaderComponent} from './content/admin/header/header.component';
import {LayoutComponent} from './layout/layout.component';
import {AuthInterceptorService} from "./services/auth-interceptor.service";
import {MatPaginatorModule} from "@angular/material/paginator";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {NgToastModule} from "ng-angular-popup";
import {TreeviewModule} from "ngx-treeview";
import { LoaderButtonComponent } from './shared/loader-button/loader-button.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuNavigationComponent,
    HeaderComponent,
    LayoutComponent,
    LoaderButtonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatPaginatorModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    LoadingBarRouterModule,
    TreeviewModule.forRoot(),
    NgToastModule,
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
