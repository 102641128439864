
<div class="page-wrapper">
  <div *ngIf="isLoading" class="z-index_high progress_bar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
<app-header></app-header>

<app-sidebar></app-sidebar>

  <lib-ng-toast></lib-ng-toast>
  <router-outlet></router-outlet>


<!--  <app-footer></app-footer>-->
</div>

<!--<lib-ng-toast></lib-ng-toast>-->
