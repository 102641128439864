import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";
import {SessionManager} from "../shared/SessionManager";


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': '100',
    })
  };

  constructor(private http:HttpClient) { }

  sendOtp(requestPayload: any): Observable<any> {
    return this.http.post<any>(`https://auth.carvia-test.org/api/auth/tenant/visor/send-otp`, requestPayload, this.httpOptions);
  }

  login(mobile:any,otp:any): Observable<any> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic dmlzb3ItbW9iaWxlLWFwcDpwYXNzd29yZA==',
    };

    const data = new URLSearchParams();
    data.set('grant_type', 'custom_otp');
    data.set('username', mobile);
    data.set('password', otp);

    return this.http.post<any>(`${environment.authUrlVisor}/oauth2/token`, data.toString(), { headers: headers });
  }

  refreshToken(refreshToken: string, data: any) {
    return this.http.post<any>(`${environment.authUrlVisor}/oauth/token`,data.toString(),{
      headers:{'authorization': `Basic ${environment.basicAuth}`}
    });
  }

}
