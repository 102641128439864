import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from '@angular/router';
import {LoginService} from "../services/login.service";
import {SessionManager} from "../shared/SessionManager";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  nonce = '';
  formMobile: FormGroup | any;
  mobile = '';
  errorMessage = '';
  type = '';
  otpSent: boolean = false;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: LoginService,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.formMobile = this.fb.group({
      mobile: [''],
      deviceId: ['device#2'],
      otp: ['']
    });
    if (SessionManager.getToken()) {
      this.router.navigate(['/onBoarding-Users']);
    }
  }

  getLoginOtp(): void {
    this.loading = true;
    if (this.formMobile.invalid) {
      this.loading = false;
      return;
    } else {
      this.mobile = this.formMobile.get('mobile').value;
      this.service.sendOtp({
        mobile: this.formMobile.get('mobile').value,
      }).subscribe({
        next: res => {
          this.nonce = res.nonce;
          this.type = res.type;
          this.otpSent = true;
          this.loading = false;
        },
        error: err => {
          this.errorMessage = err.message;
          if (err.status == 0) {
            this.openSnackBar('Please connect with you internet', 'OK')
            this.errorMessage = 'Please connect with you internet';
          }
        },
      })
    }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  login(): void {
    this.loading = true;
    if (this.formMobile.invalid) {
      return;
    }

    this.service.login( this.mobile, this.formMobile.get('otp').value).subscribe({
      next: res => {
        SessionManager.createSession(res.access_token, res.id, res.refreshToken, res.firstName + ' ' + res.lastName);
        console.log(res,'res')
        this.router.navigate(['onBoarding-Users']);
        this.loading = false;
      },
      error: err => {
        this.errorMessage = err.message;
        this.loading = false;
        this.openSnackBar(err.error.message, 'ERROR')
      },
    })
  }


}
